@import './../../../scss/general/breakpoints.scss';

#navigation {
	padding: 0;
	display: flex;
	background: #00be96;
	margin: 0;
	width: 100%;
	position: fixed;
	z-index: 100;
	top: 0;

	.row-inner {
		display: flex;
		align-items: center;
		height: 120px;
		position: relative;

		@include breakpoint(mobile) {
			height: 90px;
		}
	}

	.icon-marker {
		height: 50px;
		width: auto;
	}

	ul {
		list-style: none;
		display: flex;
		margin: 0;
		padding: 0;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);

		@include breakpoint(mobile) {
			right: 14px;
		}
	}

	li {

		a {
			padding: 20px 20px;
			font-size: 15px;
			letter-spacing: 0.2px;
			font-family: 'berlingske_extrabold';
			text-decoration: none;
			display: inline-block;
			color: #2f3234;

			@include breakpoint(mobile) {
				padding: 10px 6px;
			}

			span {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					height: 2px;
					background: #2f3234;
					bottom: 0;
					left: 0;
					transition: transform ease 0.2s;
					transform: scaleX(0);
					transform-origin: left;
				}
			}


			&:hover, &.active {
				span {
					&:after {
						transform: scaleX(1);
					}
				}
			}
		}
	}
}