@mixin breakpoint($point) {
    @if $point == small {
        @media screen and (max-width: 1050px) {
            @content;
        }
    }

    @if $point == mobile {
        @media screen and (max-width: 650px) {
            @content;
        }
    }
}